import ColoursList from "./positive_property_colours_and_patterns";
/*
    retrieve background & border colours by positive property
*/
export default function (data, positivePropertyName, property) {
    // initialise empty object to fill & return values from
    const colours = {};
    // retrieve all positive properties from the data
    const positiveProperties = data["Positive Properties"];
    // loop over positive properties
    positiveProperties.forEach((positiveProperty) => {
        const name = positiveProperty["Term"];
        // find the row which matches `positivePropertyName`
        if (name === positivePropertyName) {
            // store the relevant colour pair number
            const colourPairNumber = positiveProperty["Colour Pattern Pair"];
            colours.background = ColoursList[colourPairNumber].background;
            colours.border = ColoursList[colourPairNumber].border;
        }
    });
    // if no colours have been returned, this is not a positive property,
    // so apply default harm colours
    if (Object.keys(colours).length === 0) {
        colours.background = "hsla(37, 100%, 85%, 1)";
        colours.border = "hsla(37, 100%, 75%, 1)";
    }
    // instantiate colour variable to return
    let colour;
    // return correct colour based on property argument
    if (property === "background") {
        colour = colours.background;
    } else if (property === "border") {
        colour = colours.border;
    }
    // return colour value
    return colour;
}
