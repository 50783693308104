"use strict";
// import functions
import FocusAccessibility from "./modules/focus_accessibility";
import TypeRatioInterpolation from "./modules/type_ratio_interpolation";
import RephrainMap from "./modules/rephrain_map";
/*
    interpolate between type ratio minimum and maximum values,
    and store the result in a CSS custom property
*/
TypeRatioInterpolation();
/*
    handle display of map data
*/
RephrainMap();
/*
    allow enchanced focus detection (depends on a11y.js)
*/
FocusAccessibility();
