import RetrieveColours from "./retrieve_positive_property_colours";
/*
    colour related positive properties on a harm result
*/
export default function (data) {
    // retrieve all related positive properties
    const list = document.getElementById("harm_positive_properties_list");
    if (list) {
        // loop over related positive properties
        for (const item of list.children) {
            // retrieve child button
            const button = item.firstElementChild;
            if (button) {
                // retrieve related positive property name
                const name = button.innerText;
                const backgroundColour = RetrieveColours(data, name, "background");
                const borderColour     = RetrieveColours(data, name, "border");
                // set background colour
                button.style.backgroundColor = backgroundColour;
                // set border colour
                button.style.borderColor = borderColour;
            }
        }
    }
}
