/*
    copy resource URL when user clicks URL copy button
*/
export default function () {
    // retrieve all URL copy button containers
    const copyButtonContainerClass = ".harm__related_resources__table__resource_location";
    const copyButtonContainers     = document.querySelectorAll(copyButtonContainerClass);
    // for each URL copy button container, if present
    if (copyButtonContainers) {
        copyButtonContainers.forEach(copyButtonContainer => {
            // retreive URL
            const urlClass = ".harm__related_resources__table__resource_anchor";
            const url      = copyButtonContainer.querySelector(urlClass);
            // retreive copy button
            const copyButtonClass = ".harm__related_resources__table__copy_anchor_button";
            const copyButton      = copyButtonContainer.querySelector(copyButtonClass);
            if (url && copyButton) {
                copyButton.addEventListener("click", () => {
                    // write URL to the clipboard
                    const href = url.href;
                    // retrieve url copy notice dialog
                    const copyNotice          = document.getElementById("url_copy_dialog");
                    const copyNoticeFadeClass = "harm__url_copy_dialog--hide";
                    const copyNoticeText      = copyNotice.querySelector(".harm__url_copy_dialog__message");
                    const dialogDelay         = 1500;
                    navigator.clipboard.writeText(href).then(() => {
                        // clipboard write set
                        if (copyNotice && copyNoticeText) {
                            copyNoticeText.innerText = "URL copied";
                            copyNotice.showModal();
                            copyNotice.classList.add(copyNoticeFadeClass);
                            setTimeout(() => {
                                copyNotice.close();
                                copyNotice.classList.remove(copyNoticeFadeClass);
                            }, dialogDelay);
                        }
                    }, () => {
                        // clipboard write failed
                        if (copyNotice && copyNoticeText) {
                            copyNoticeText.innerText = "There was an error in copying the URL";
                            copyNotice.showModal();
                            copyNotice.classList.add(copyNoticeFadeClass);
                            setTimeout(() => {
                                copyNotice.close();
                                copyNotice.classList.remove(copyNoticeFadeClass);
                            }, dialogDelay);
                        }
                    });
                });
            }
        });
    }
}
