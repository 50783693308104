/* global $ d3 */
import AssignPositivePropertyGraphic from "./assign_positive_property_graphic_svg";
import ColourRelatedPositiveProperties from "./colour_related_positive_properties";
import ColourPositivePropertiesResult from "./colour_positive_property_result";
// import DialogAccessibility from "./dialog_accessibility";
import ResourceTypeInformation from "./handle_resource_type_information_visibility";
import RetrieveColours from "./retrieve_positive_property_colours";
import UrlCopyButton from "./url_copy_button";
/*
    handle display of map data
*/
export default function () {
    function intern (value) {
        return value !== null && typeof value === "object" ? value.valueOf() : value;
    }

    function convertToLinks (data) {
        let links = [];
        data.forEach((row) => {
            row["Positive Properties"].forEach(
                function (property) { links.push({"source": property, "target": row["Term"], "value": 1}); }
            );
        });
        // De-duplicate links which point between the same source and target
        links = links.filter((value, index, self) =>
            index === self.findIndex((link) => (
                link.source === value.source && link.target === value.target
            ))
        );
        return links;
    }

    function indexData (data, key) {
        const indexedData = {};
        data.forEach((row) => indexedData[row[key]] = row);
        return indexedData;
    }

    function pushToNewUrl (urlParams) {
        const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + urlParams.toString();
        window.history.pushState({path: newurl}, "", newurl);
        renderGraph();
    }

    function addSelectedNodesToSearchTerms () {
        if (history.pushState) {
            const urlParams = new URLSearchParams(window.location.search);
            var selectedQueryParams = urlParams.getAll("selected");
            var filterQueryParams = urlParams.getAll("filter");
            selectedQueryParams.forEach((selectedNode) => {
                if (!filterQueryParams.includes(selectedNode)) {
                    urlParams.append("filter", selectedNode);
                }
            });
            pushToNewUrl(urlParams);
        }
    }

    const addToSearchButton = document.getElementById("add_to_search_button");
    addToSearchButton.addEventListener("click", addSelectedNodesToSearchTerms);

    function replaceSelectedNodesToSearchTerms () {
        if (history.pushState) {
            const urlParams = new URLSearchParams(window.location.search);
            var selectedQueryParams = urlParams.getAll("selected");
            urlParams.delete("filter");
            selectedQueryParams.forEach((selectedNode) => {
                urlParams.append("filter", selectedNode);
            });
            pushToNewUrl(urlParams);
        }
    }

    const replaceToSearchButton = document.getElementById("replace_search_button");
    replaceToSearchButton.addEventListener("click", replaceSelectedNodesToSearchTerms);

    function deselectNodes () {
        if (history.pushState) {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.delete("focus");
            urlParams.delete("selected");
            pushToNewUrl(urlParams);
        }
    }

    const deselectNodesButton = document.getElementById("deselect_nodes_button");
    deselectNodesButton.addEventListener("click", deselectNodes);

    function replaceResourceTypeWrapper (resourceType) {
        return function () {
            if (history.pushState) {
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.delete("resource_type");
                // If resourceType is "All" then just remove `resource_type` query param altogether
                if (resourceType !== "All") {
                    urlParams.append("resource_type", resourceType);
                }
                pushToNewUrl(urlParams);
            }
        };
    }

    function clickRelatedButtonWrapper (term) {
        return function () {
            if (history.pushState) {
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.delete("focus");
                urlParams.delete("selected");
                urlParams.append("focus", term);
                urlParams.append("selected", term);

                pushToNewUrl(urlParams);
            }
        };
    }

    function getDetailContent (dictionary, tags, content="") {
        for (const [key, value] of Object.entries(dictionary)) {
            if (typeof value === "object" && !Array.isArray(value)) {
                content = getDetailContent(value, tags, content);
            } else {
                // if there is no value, display `none`
                let displayedValue = value.length === 0 ? "<span class=\"none_value\">(none)</span>": value || "<span class=\"none_value\">(none)</span>";
                // define names of tag types
                const tagNames = Object.keys(tags);
                // if `definedValue` is an array
                if (Array.isArray(displayedValue)) {
                    // if `definedValue` is a tag or a harm, split into span elements
                    // there is an issue here currently where adding a class causes this to break
                    if (tagNames.includes(key) || key === "Harms") {
                        displayedValue = displayedValue.map((item) => {
                            return `<span>${item}</span>`;
                        }).join("");
                    // if `definedValue` is an author, separate by commas
                    } else {
                        displayedValue = displayedValue.join(", ");
                    }
                }
                // check whether this is displaying a harm
                if (key === "Harms") {
                    content += `
                        <span class="harm__related_resources__dialog__item harm__related_resources__dialog__item--harm">
                            <dt class="harm__related_resources__dialog__term">${key}</dt>
                            <dd class="harm__related_resources__dialog__description">${displayedValue}</dd>
                        </span><!-- .harm__related_resources__dialog__item -->
                    `;
                // check whether this is displaying a tag
                } else if (tagNames.includes(key)) {
                    content += `
                        <span class="harm__related_resources__dialog__item harm__related_resources__dialog__item--tag">
                            <dt class="harm__related_resources__dialog__term">${key}</dt>
                            <dd class="harm__related_resources__dialog__description">${displayedValue}</dd>
                        </span><!-- .harm__related_resources__dialog__item -->
                    `;
                // check whether this is displaying a URL value
                } else if (key === "Url") {
                    // handle markup if no URL is present
                    if (displayedValue.includes("(none)")) {
                        content += `
                            <span class="harm__related_resources__dialog__item">
                                <dt class="harm__related_resources__dialog__term">External link</dt>
                                <dd class="harm__related_resources__dialog__description">${displayedValue}</dd>
                            </span><!-- .harm__related_resources__dialog__item -->
                        `;
                    // handle markup if URL is present
                    } else {
                        content += `
                            <span class="harm__related_resources__dialog__item">
                                <dt class="harm__related_resources__dialog__term">External link</dt>
                                <dd class="harm__related_resources__dialog__description"><a href="${displayedValue}">${displayedValue.slice(0, displayedValue.lastIndexOf("/"))}</a></dd>
                            </span><!-- .harm__related_resources__dialog__item -->
                        `;
                    }
                // otherwise, this is a simple string value
                } else {
                    content += `
                        <span class="harm__related_resources__dialog__item">
                            <dt class="harm__related_resources__dialog__term">${key}</dt>
                            <dd class="harm__related_resources__dialog__description">${displayedValue}</dd>
                        </span><!-- .harm__related_resources__dialog__item -->
                    `;
                }
            }
        }
        return content;
    }

    function populateResourceDialogWrapper (resource, tags) {
        return function () {
            const dialog     = document.getElementById("resource_dialog");
            const dialogList = document.getElementById("resource_dialog_list");
            dialogList.innerHTML = getDetailContent(resource, tags);
            dialog.showModal();
        };
    }

    function createSelect2Option (term, optgroupElement, select2Element, filterQueryParamName, appendOptGroup=false) {
        var urlParams = new URLSearchParams(window.location.search);
        var filterQueryParams = urlParams.getAll(filterQueryParamName);

        var option = document.createElement("option");
        option.value = appendOptGroup ? optgroupElement.label + ":" + term : term;
        option.innerHTML = term;
        if (filterQueryParams.includes(option.value)) {
            option.selected = true;
        } else {
            option.selected = false;
        }
        optgroupElement.appendChild(option);
        select2Element[0].appendChild(optgroupElement);
    }

    function matchStart (params, data) {
        // If there are no search terms, return all of the data
        if ($.trim(params.term) === "") {
            return data;
        }

        // Skip if there is no 'children' property
        if (typeof data.children === "undefined") {
            return null;
        }

        // `data.children` contains the actual options that we are matching against
        var filteredChildren = [];
        $.each(data.children, function (idx, child) {
            if (child.text.toUpperCase().includes(params.term.toUpperCase())) {
                filteredChildren.push(child);
            }
        });

        // If we matched any of the group's children, then set the matched children on the group
        // and return the group object
        if (filteredChildren.length) {
            var modifiedData = $.extend({}, data, true);
            modifiedData.children = filteredChildren;
            return modifiedData;
        }

        // Return `null` if the term should not be displayed
        return null;
    }

    function initialiseSelect2 (originalLinks) {
        const select2Element = $("#search_input");
        select2Element.empty();

        // Add positive property optgroup with options
        var optGroupPositiveProperties = document.createElement("optgroup");
        optGroupPositiveProperties.label = "Positive Properties";
        const positiveProperties = new Set();
        originalLinks.forEach(link => {positiveProperties.add(link.source);});
        positiveProperties.forEach(term => {
            createSelect2Option(term, optGroupPositiveProperties, select2Element, "filter");
        });

        // Add harms optgroup with options
        var optGroupHarms = document.createElement("optgroup");
        optGroupHarms.label = "Harms";
        const harms = new Set();
        originalLinks.forEach(link => {harms.add(link.target);});
        harms.forEach(term => {
            createSelect2Option(term, optGroupHarms, select2Element, "filter");
        });

        // initialise chosen on the select element
        select2Element.select2({"width": "320px", "matcher": matchStart}).css({"font-size": "16"});
    }

    function createTagOptGroup (tagName, tags, select2Element, appendOptGroup=false) {
        // Add harms optgroup with options
        var optGroup = document.createElement("optgroup");
        optGroup.label = tagName;
        tags.forEach(tag => {
            createSelect2Option(tag, optGroup, select2Element, "resource_filter", appendOptGroup);
        });
    }

    function initialiseResourcesSelect2 (termData, indexedResourceData) {
        const select2Element = $("#resource_search_input");
        select2Element.empty();

        // Only add tags to resource table search if tag will actually return at least one result
        const tagSets = {};
        termData["Resources"].forEach(resourceName => {
            const resource = indexedResourceData[resourceName];

            if (!resource) {
                return;
            }

            for (const [tagType, tags] of Object.entries(resource["Tags"])) {
                if (!(tagType in tagSets)) {
                    tagSets[tagType] = new Set();
                }
                tags.forEach(tag => tagSets[tagType].add(tag));
            }
        });

        for (const [tagName, tags] of Object.entries(tagSets)) {
            createTagOptGroup(tagName, Array.from(tags).sort(), select2Element, true);
        }

        // initialise chosen on the select element
        select2Element.select2({"width": "320px", "matcher": matchStart}).css({"font-size": "16"});
    }

    function select2SubmitWrapper (selectId, filterQueryParamName) {
        return function () {
            const select2Element = document.getElementById(selectId);
            const options = select2Element.options;

            const urlParams = new URLSearchParams(window.location.search);
            urlParams.delete(filterQueryParamName);
            if (history.pushState) {
                for (var i = 0; i < options.length; i++) {
                    if (options[i].selected) {
                        urlParams.append(filterQueryParamName, options[i].value);
                    }
                }
            }
            pushToNewUrl(urlParams);
        };
    }

    function select2ClearWrapper (filterQueryParamName) {
        return function () {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.delete(filterQueryParamName);
            pushToNewUrl(urlParams);
        };
    }

    // Get all nodes which are connected to selected nodes
    function getSelectedConnectedNodes (svg, selectedQueryParams) {
        const connectedNodes = [];

        for (const selectedQueryParam of selectedQueryParams) {
            // When looping over paths target and source are not defined, therefore use `attr`
            svg.selectAll("path").attr("arbitrary-attribute", path => {
                if (path.target.id === selectedQueryParam) {
                    connectedNodes.push(path.source.id);
                } else if (path.source.id === selectedQueryParam) {
                    connectedNodes.push(path.target.id);
                }
            });
        }

        return connectedNodes;
    }

    // eslint-disable-next-line no-unused-vars
    function sankeyChart ({
        nodes, // an iterable of node objects (typically [{id}, …]); implied by links if missing
        data,
    },
    {
        align = "justify", // convenience shorthand for nodeAlign
        // eslint-disable-next-line no-unused-vars
        height, // outer height, in pixels
        linkColor = "source", // source, target, source-target, or static color
        linkMixBlendMode = "multiply", // link blending mode
        linkPath = d3.sankeyLinkHorizontal(), // given d in (computed) links, returns the SVG path
        linkSource = ({source}) => source, // given d in links, returns a node identifier string
        linkStrokeOpacity = 0.85, // link stroke opacity
        linkStrokeWidth = 3, // link stroke width
        linkTarget = ({target}) => target, // given d in links, returns a node identifier string
        linkTitle = d => `${d.source.id} → ${d.target.id}`, // given d in (computed) links
        linkValue = ({value}) => value, // given d in links, returns the quantitative value
        marginBottom = 0, // bottom margin, in pixels
        marginLeft = 1, // left margin, in pixels
        marginRight = 1, // right margin, in pixels
        marginTop = 0, // top margin, in pixels
        nodeAlign = align, // Sankey node alignment strategy: left, right, justify, center
        nodeGroup, // given d in nodes, returns an (ordinal) value for color
        nodeGroups, // an array of ordinal values representing the node groups
        nodeId = d => d.id, // given d in nodes, returns a unique identifier (string)
        nodeLabel, // given d in (computed) nodes, text to label the associated rect
        nodeLabelPadding = -68, // horizontal separation between node and label
        nodeOpacity = 0.85, // Opacity of node
        nodePadding = 3, // vertical separation between adjacent nodes
        nodeStrokeLinejoin, // line join for stroke around node rects
        nodeStrokeWidth = 1, // width of stroke around node rects, in pixels
        nodeTitle = d => `${d.id}`, // given d in (computed) nodes, hover text
        nodeWidth = 136, // width of node rects
        selectedNodeStroke = "#00c0ad", // colour for selected nodes
        width, // outer width, in pixels
        nodeHeightPowerConstant = 0, // the power relation between node height and number of connections
        linkSpreadConstant = 0.9, // how much spread is there between links, must be between 0 and 1
        fixedNodeValue = false, // whether all nodes should have the same value (and therefore height)
        spaceNodesEqually = true, // whether to space nodes equally
    } = {}) {
        const svg = d3.select("#graph_target_svg");
        // Remove exist elements inside SVG incase this function is called within the same page load
        svg.selectAll("g").remove();

        let links = convertToLinks(data["Harms"]);

        const originalLinks = [...links];

        var urlParams = new URLSearchParams(window.location.search);
        var filterQueryParams = urlParams.getAll("filter");
        var selectedQueryParams = urlParams.getAll("selected");
        var focusQueryParam = urlParams.get("focus");

        if (filterQueryParams.length >= 1) {
            links = links.filter((link) => filterQueryParams.includes(link.source) || filterQueryParams.includes(link.target));
        }

        initialiseSelect2(originalLinks);

        // Convert nodeAlign from a name to a function (since d3-sankey is not part of core d3).
        if (typeof nodeAlign !== "function") nodeAlign = {
            left: d3.sankeyLeft,
            right: d3.sankeyRight,
            center: d3.sankeyCenter,
        }[nodeAlign] ?? d3.sankeyJustify;

        // Compute values.
        const LS = links.map(linkSource).map(intern);
        const LT = links.map(linkTarget).map(intern);
        const LV = links.map(linkValue);
        if (nodes === undefined) nodes = Array.from(d3.union(LS, LT), id => ({id}));
        const N = nodes.map(nodeId).map(intern);
        const G = nodeGroup === null ? null : nodes.map(nodeGroup).map(intern);

        // Replace the input nodes and links with mutable objects for the simulation.
        nodes = nodes.map((_, i) => ({id: N[i]}));
        links = links.map((_, i) => ({source: LS[i], target: LT[i], value: LV[i]}));

        // Ignore a group-based linkColor option if no groups are specified.
        if (!G && ["source", "target", "source-target"].includes(linkColor)) linkColor = "currentColor";

        // compute default domains.
        if (G && nodeGroups === undefined) nodeGroups = G;
        /*
            compute the Sankey layout
        */
        d3.sankey()
            .nodeId(({index: i}) => N[i])
            .nodeAlign(nodeAlign)
            .nodeWidth(nodeWidth)
            .nodePadding(nodePadding)
            .nodeHeightPowerConstant(nodeHeightPowerConstant)
            .linkSpreadConstant(linkSpreadConstant)
            .fixedNodeValue(fixedNodeValue)
            .spaceNodesEqually(spaceNodesEqually)
            .extent([[marginLeft, marginTop], [width - marginRight, height - marginBottom]])({nodes, links});

        // Compute titles and labels using layout nodes, so as to access aggregate values.
        const Tl = nodeLabel === undefined ? N : nodeLabel === null ? null : nodes.map(nodeLabel);
        const Tt = nodeTitle === null ? null : nodes.map(nodeTitle);
        const Lt = linkTitle === null ? null : links.map(linkTitle);

        // A unique identifier for clip paths (to avoid conflicts).
        const uid = `O-${Math.random().toString(16).slice(2)}`;

        svg.attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

        const node = svg.append("g")
            .attr("id", "svg-node-group")
            .selectAll("rect")
            .data(nodes)
            .join("rect")
            .attr("x", d => d.x0)
            .attr("y", d => d.y0)
            .attr("height", d => d.y1 - d.y0)
            .attr("width", d => d.x1 - d.x0)
            .attr("stroke-opacity", 1)
            .attr("stroke-linejoin", nodeStrokeLinejoin)
            .style("opacity", nodeOpacity)
            .style("cursor", "pointer")
            .attr("rx", 5)
            .on("click", function (d, i) {
                svg.selectAll("rect").attr("stroke-width", nodeStrokeWidth).attr("stroke", ({index: i}) => RetrieveColours(data, G[i], "border"));

                var urlParams = new URLSearchParams(window.location.search);
                var selectedQueryParams = urlParams.getAll("selected");

                if (history.pushState) {
                    if (!selectedQueryParams.includes(i.id)) {
                        urlParams.append("selected", i.id);
                    } else {
                        urlParams.delete("selected");
                        selectedQueryParams.forEach((selectedNode) => {
                            if (selectedNode !== i.id) {
                                urlParams.append("selected", selectedNode);
                            }
                        });
                    }

                    selectedQueryParams = urlParams.getAll("selected");

                    urlParams.delete("focus");
                    if (!selectedQueryParams.includes(i.id)) {
                        var lastSelected = selectedQueryParams[selectedQueryParams.length - 1];
                        if (typeof lastSelected !== "undefined") {
                            urlParams.append("focus", lastSelected);
                        }
                        svg.selectAll("text")
                            .attr("text-decoration", d => lastSelected === d.id ? "underline" : "none");
                    } else {
                        urlParams.append("focus", i.id);
                        svg.selectAll("text")
                            .attr("text-decoration", d => i.id === d.id ? "underline" : "none");
                    }

                    pushToNewUrl(urlParams);
                }
            })
            .on("mouseover", function (d, i) {
                // Get nodes connected to hovered node
                const selectedConnectedNodes = getSelectedConnectedNodes(svg, [i.id]);

                // When hovering over a node, highlight connected nodes
                svg.selectAll("rect")
                    .attr("stroke-width", d => selectedConnectedNodes.includes(d.id) ? 2 * nodeStrokeWidth : nodeStrokeWidth)
                    .attr("stroke", d => selectedConnectedNodes.includes(d.id) ? "black" : RetrieveColours(data, G[d.index], "border"));

                d3.select(this).style("opacity", 1).attr("stroke-width", 3 * nodeStrokeWidth).attr("stroke", "black");
                svg.selectAll("path").attr(
                    "stroke-opacity",
                    path => path.source.id === i.id || path.target.id === i.id ? 1 : linkStrokeOpacity
                ).attr(
                    "stroke-width",
                    path => path.source.id === i.id || path.target.id === i.id ? 2 * linkStrokeWidth : linkStrokeWidth
                );
            })
            .on("mouseout", function () {
                var urlParams = new URLSearchParams(window.location.search);
                var selectedQueryParams = urlParams.getAll("selected");
                const selectedConnectedNodes = getSelectedConnectedNodes(svg, selectedQueryParams);

                svg.selectAll("rect")
                    .style("opacity", nodeOpacity)
                    .attr("stroke-width", d => selectedQueryParams.includes(d.id) ? 3 * nodeStrokeWidth : selectedConnectedNodes.includes(d.id) ? 2 * nodeStrokeWidth : nodeStrokeWidth)
                    .attr("stroke", d => selectedQueryParams.includes(d.id) ? selectedNodeStroke : selectedConnectedNodes.includes(d.id) ? "black" : RetrieveColours(data, G[d.index], "border"));
                svg.selectAll("path")
                    .attr("stroke-opacity", d => selectedQueryParams.includes(d.source.id) || selectedQueryParams.includes(d.target.id) ? 1 : linkStrokeOpacity)
                    .attr("stroke-width", d => selectedQueryParams.includes(d.source.id) || selectedQueryParams.includes(d.target.id) ? 2 * linkStrokeWidth : linkStrokeWidth);
            });

        if (G) node.attr("fill", ({index: i}) => RetrieveColours(data, G[i], "background"));
        if (Tt) node.append("title").text(({index: i}) => Tt[i]);

        // Use insert so that path group is before node group in html and hence nodes are rendered
        // above paths
        const link = svg.insert("g", "#svg-node-group")
            .attr("id", "svg-path-group")
            .attr("fill", "none")
            .attr("stroke-opacity", linkStrokeOpacity)
            .selectAll("g")
            .data(links)
            .join("g")
            .style("mix-blend-mode", linkMixBlendMode);

        if (linkColor === "source-target") link.append("linearGradient")
            .attr("id", d => `${uid}-link-${d.index}`)
            .attr("gradientUnits", "userSpaceOnUse")
            .attr("x1", d => d.source.x1)
            .attr("x2", d => d.target.x0)
            .call(gradient => gradient.append("stop")
                .attr("offset", "0%")
                .attr("stop-color", ({source: {index: i}}) => RetrieveColours(data, G[i], "background")))
            .call(gradient => gradient.append("stop")
                .attr("offset", "100%")
                .attr("stop-color", ({target: {index: i}}) => RetrieveColours(data, G[i], "background")));

        link.append("path")
            .attr("d", linkPath)
            .attr("stroke", linkColor === "source-target" ? ({index: i}) => `url(#${uid}-link-${i})`
                : linkColor === "source" ? ({source: {index: i}}) => RetrieveColours(data, G[i], "background")
                    : linkColor === "target" ? ({target: {index: i}}) => RetrieveColours(data, G[i], "background")
                        : linkColor)
            .attr("stroke-width", (d) => selectedQueryParams.includes(d.source.id) || selectedQueryParams.includes(d.target.id) ? 2 * linkStrokeWidth : linkStrokeWidth)
            .attr("stroke-opacity", (d) => selectedQueryParams.includes(d.source.id) || selectedQueryParams.includes(d.target.id) ? 1 : linkStrokeOpacity)
            .call(Lt ? path => path.append("title").text(({index: i}) => Lt[i]) : () => {});

        if (Tl) svg.append("g")
            .attr("font-family", "tt_commons__book")
            .selectAll("text")
            .data(nodes)
            .join("text")
            .attr("x", d => d.x0 < width / 2 ? d.x1 + nodeLabelPadding : d.x0 - nodeLabelPadding)
            .attr("y", d => (d.y1 + d.y0) / 2)
            .attr("dy", "0.35em")
            .attr("text-anchor", d => d.x0 < width / 2 ? "start" : "end")
            .text(({index: i}) => Tl[i])
            .attr("text-anchor", "middle")
            .style("font-size", d => d.layer === 0 ? "12px" : "9px" )
            .style("pointer-events", "none")
            .attr("text-decoration", d => focusQueryParam === d.id ? "underline" : "none")
            .style("font-weight", (i) => filterQueryParams.includes(i.id) ? "bold": "normal");

        // Make sure nodes connected to selected nodes are highlighted on page load
        const selectedConnectedNodes = getSelectedConnectedNodes(svg, selectedQueryParams);
        svg.selectAll("rect")
            .attr("stroke", d => selectedQueryParams.includes(d.id) ? selectedNodeStroke : selectedConnectedNodes.includes(d.id) ? "black" : RetrieveColours(data, G[d.index], "border"))
            .attr("stroke-width", d => selectedQueryParams.includes(d.id) ? 3 * nodeStrokeWidth : selectedConnectedNodes.includes(d.id) ? 2 * nodeStrokeWidth : nodeStrokeWidth);

        updatePageContent(data);

        return Object.assign(svg.node(), {scales: {RetrieveColours}});
    }
    /*
        functions to display results when user selects a node
    */
    function updatePageContent (data) {
        // retrieve current URL query parameters
        const urlParams       = new URLSearchParams(window.location.search);
        // retrieve parameters matching the query 'focus'
        const focusQueryParam = urlParams.get("focus");

        // retrieve, if present, harm data for current focused node
        // we use this to determine if focused node is a harm or a positive property
        const indexedHarmData      = indexData(data["Harms"], "Term");
        const indexedProjectsData  = indexData(data["Projects"], "Title");
        const indexedResourceTypes = indexData(data["Resource Types"], "Term");
        const indexedToolsData     = indexData(data["Tools"], "Title");
        let termData               = indexedHarmData[focusQueryParam];
        // retrieve harm section in results, and hide it from the user for content population
        const harm = document.getElementById("harm_item");
        // if harm section isn't present, return (in case of future non-map pages)
        if (!harm) {
            return;
        }
        // retrieve positive property section in results, and hide it from the user for content population
        const positiveProperty = document.getElementById("positive_property_item");
        // if positive property section isn't present, return (in case of future non-map pages)
        if (!positiveProperty) {
            return;
        }
        /*
            if `termData` is defined at this point, current focused node is a harm
        */
        if (termData) {
            // hide results containers
            positiveProperty.hidden = true;
            harm.hidden = true;
            // store variables for specific term data
            const termName               = termData["Term"];
            const termDescription        = termData["Term Description"];
            const termPositiveProperties = termData["Positive Properties"];
            const termChallenges         = termData["Challenges"];
            const termResources          = termData["Resources"];
            const termProjects           = termData["Projects"];
            const termTools              = termData["Tools"];
            // store elements that will be populated with content
            const harmItem                   = document.getElementById("harm_item");
            const harmHeading                = document.getElementById("harm_heading");
            const harmDescription            = document.getElementById("harm_description");
            const harmPositiveProperties     = document.getElementById("harm_positive_properties");
            const harmPositivePropertiesList = document.getElementById("harm_positive_properties_list");
            const harmChallenges             = document.getElementById("harm_challenges");
            const harmChallengesList         = document.getElementById("harm_challenges_list");
            const harmProjects               = document.getElementById("harm_projects");
            const harmProjectsList           = document.getElementById("harm_projects_list");
            const harmTools                  = document.getElementById("harm_tools");
            const harmToolsList              = document.getElementById("harm_tools_list");
            const harmResources              = document.getElementById("harm_resources");
            const harmResourcesTableBody     = document.getElementById("harm_resources_table_body");
            const harmResourcesTypeFilter    = document.getElementById("harm_resources_type_filter");
            // set aria-label and data-heading on harm list item to be picked up by CSS
            harmItem.ariaLabel = `Harm: ${termName}`;
            harmItem.dataset.heading = `Harm: ${termName}`;
            // populate harm heading with content
            harmHeading.innerText = termName;
            // populate harm description with content
            if (termDescription) {
                harmDescription.innerHTML = `<p>${termDescription}</p>`;
                harmDescription.hidden = false;
            } else {
                harmDescription.hidden = true;
            }
            // populate all harm positive properties with content
            if (termPositiveProperties) {
                const positivePropertiesContent = termPositiveProperties.map((positiveProperty) => {
                    return `
                        <li class="harm__related_positive_properties__item">
                            <button
                            id="related_positive_property_button_${positiveProperty}"
                            class="harm__related_positive_properties__button"
                            aria-label="${positiveProperty}">${positiveProperty}</button>
                        </li><!-- .harm__related_positive_properties__item -->
                    `;
                });
                harmPositivePropertiesList.innerHTML = positivePropertiesContent.join("\n");
                harmPositiveProperties.hidden = false;
                termPositiveProperties.forEach(
                    positiveProperty => {
                        const positivePropertyButton = document.getElementById(`related_positive_property_button_${positiveProperty}`);
                        positivePropertyButton.addEventListener("click", clickRelatedButtonWrapper(positiveProperty));
                    }
                );
            }

            // populate all harm challenges with content
            if (termChallenges && termChallenges.length) {
                const challengesContent = termChallenges.map((challenge) => {
                    return `
                        <li class="harm__challenges__item">
                            <span class="harm__challenges__challenge">${challenge}</span>
                        </li><!-- .harm__challenges__item -->
                    `;
                });
                harmChallengesList.innerHTML = challengesContent.join("\n");
                harmChallenges.hidden = false;
            } else {
                harmChallenges.hidden = true;
            }
            // store code for external link icon, for re-use
            const externalLinkSVG = `
                <svg
                class=""
                fill="none"
                height="15"
                viewBox="0 0 15 15"
                width="15"
                xmlns="http://www.w3.org/2000/svg">
                    <path
                    clip-rule="evenodd"
                    d="M3 2C2.44772 2 2 2.44772 2 3V12C2 12.5523 2.44772 13 3 13H12C12.5523 13 13 12.5523 13 12V8.5C13 8.22386 12.7761 8 12.5 8C12.2239 8 12 8.22386 12 8.5V12H3V3L6.5 3C6.77614 3 7 2.77614 7 2.5C7 2.22386 6.77614 2 6.5 2H3ZM12.8536 2.14645C12.9015 2.19439 12.9377 2.24964 12.9621 2.30861C12.9861 2.36669 12.9996 2.4303 13 2.497L13 2.5V2.50049V5.5C13 5.77614 12.7761 6 12.5 6C12.2239 6 12 5.77614 12 5.5V3.70711L6.85355 8.85355C6.65829 9.04882 6.34171 9.04882 6.14645 8.85355C5.95118 8.65829 5.95118 8.34171 6.14645 8.14645L11.2929 3H9.5C9.22386 3 9 2.77614 9 2.5C9 2.22386 9.22386 2 9.5 2H12.4999H12.5C12.5678 2 12.6324 2.01349 12.6914 2.03794C12.7504 2.06234 12.8056 2.09851 12.8536 2.14645Z"
                    fill="currentColor"
                    fill-rule="evenodd"/>
                </svg>
            `;
            /*
                populate all harm REPHRAIN projects with content
            */
            if (termProjects && termProjects.length) {
                const projectsContent = termProjects.map((projectTitle) => {
                    const projectDetails     = indexedProjectsData[projectTitle];
                    const projectAnchor      = projectDetails["Url"];
                    const projectDescription = projectDetails["Description"];
                    return `
                        <li class="harm__rephrain_internal__item">
                            <a
                            class="harm__rephrain_internal__item__anchor"
                            href="${projectAnchor}"
                            title="${projectTitle}">
                                <h3 class="harm__rephrain_internal__item__heading">${projectTitle}</h3>
                                <span class="harm__rephrain_internal__item__description">${projectDescription}</span>
                                <span class="harm__rephrain_internal__item__link_signifier">
                                    <span class="harm__rephrain_internal__item__link_signifier__text">Visit project</span>
                                    <span class="harm__rephrain_internal__item__link_signifier__icon">${externalLinkSVG}</span>
                                </span><!-- .harm__rephrain_internal__item__link_signifier -->
                            </a><!-- .harm__rephrain_internal__item__anchor -->
                        </li><!-- .harm__rephrain_internal__item -->
                    `;
                });
                harmProjectsList.innerHTML = projectsContent.join("\n");
                harmProjects.hidden = false;
            } else {
                harmProjects.hidden = true;
            }
            /*
                populate all harm REPHRAIN tools with content
            */
            if (termTools && termTools.length) {
                const toolsContent = termTools.map((toolTitle) => {
                    const toolDetails     = indexedToolsData[toolTitle];
                    const toolAnchor      = toolDetails["Url"];
                    const toolDescription = toolDetails["Description"];
                    return `
                        <li class="harm__rephrain_internal__item">
                            <a
                            class="harm__rephrain_internal__item__anchor"
                            href="${toolAnchor}"
                            title="${toolTitle}">
                                <h3 class="harm__rephrain_internal__item__heading">${toolTitle}</h3>
                                <span class="harm__rephrain_internal__item__description">${toolDescription}</span>
                                <span class="harm__rephrain_internal__item__link_signifier">
                                    <span class="harm__rephrain_internal__item__link_signifier__text">Visit tool</span>
                                    <span class="harm__rephrain_internal__item__link_signifier__icon">${externalLinkSVG}</span>
                                </span><!-- .harm__rephrain_internal__item__link_signifier -->
                            </a><!-- .harm__rephrain_internal__item__anchor -->
                        </li><!-- .harm__rephrain_internal__item -->
                    `;
                });
                harmToolsList.innerHTML = toolsContent.join("\n");
                harmTools.hidden = false;
            } else {
                harmTools.hidden = true;
            }
            const indexedResourceData    = indexData(data["Resources"], "Title");
            const resourceTypes          = data["Resource Types"];
            const resourceTypeQueryParam = urlParams.get("resource_type");
            /*
                populate resource type filter buttons
            */
            if (termResources && resourceTypes) {
                harmResourcesTypeFilter.innerHTML = "";
                const presentResourceTypes = termResources.map((resourceName) => {
                    const resource = indexedResourceData[resourceName];
                    if (!resource) {
                        return;
                    }
                    return resource["Resource Type"];
                });
                // `add all` button that does not apply filter to table
                [{"Term": "All", "Term Description": "All resource types"}, ...resourceTypes].forEach(function (resourceType) {
                    const resourceTypeName = resourceType["Term"];
                    // create new list item and button elements
                    const filterItem   = document.createElement("li");
                    const filterButton = document.createElement("button");
                    // add required attributes to created list item and button elements
                    filterItem.classList.add("harm__related_resources__filters__item");
                    filterButton.classList.add("harm__related_resources__filters__button");
                    filterButton.setAttribute("title", resourceType["Term Description"]);
                    filterButton.ariaLabel = resourceTypeName;

                    filterButton.innerHTML = resourceTypeName;
                    if (resourceTypeName === resourceTypeQueryParam || (resourceTypeName === "All" && resourceTypeQueryParam === null)) {
                        if (presentResourceTypes.includes(resourceTypeName) || resourceTypeName === "All") {
                            filterButton.dataset.selected = "selected";
                        } else {
                            filterButton.dataset.selected = "disabled_and_selected";
                            filterButton.disabled = true;
                        }
                    } else {
                        if (presentResourceTypes.includes(resourceTypeName) || resourceTypeName === "All") {
                            filterButton.dataset.selected = "";
                        } else {
                            filterButton.dataset.selected = "disabled";
                            filterButton.disabled = true;
                        }
                    }
                    filterButton.addEventListener("click", replaceResourceTypeWrapper(resourceTypeName));
                    // append created button to created list item
                    filterItem.append(filterButton);
                    // append created list item to resources filter list
                    harmResourcesTypeFilter.append(filterItem);
                });
            }
            // populate all harm resources with content
            if (termResources.length) {
                // Add table rows for resources
                const resourcesContent = termResources.map((resourceName, index) => {
                    return addTableRow(resourceName, indexedResourceData, indexedResourceTypes, index);
                });
                harmResourcesTableBody.innerHTML = resourcesContent.join("\n");
                harmResources.hidden = false;

                // Add event listeners to all resource detail buttons
                termResources.forEach(function (resourceName, index) {
                    addResourceDetailsButtonEventListener(resourceName, indexedResourceData, index, data["Tags"]);
                });
            } else {
                harmResources.hidden = true;
            }
            initialiseResourcesSelect2(termData, indexedResourceData);
            // colour relevant related positive properties
            ColourRelatedPositiveProperties(data);
            // display harm section in results to user now that content is populated
            harm.hidden = false;
        } else {
            // hide results containers
            positiveProperty.hidden = true;
            harm.hidden = true;
            // retrieve, if present, positive property data for current focused node
            const indexedPositivePropertyData = indexData(data["Positive Properties"], "Term");
            termData = indexedPositivePropertyData[focusQueryParam];
            if (termData) {
                /*
                    if `termData` is defined at this point, current focused node is a positive property
                */
                // store variables for specific term data
                const termName        = termData["Term"];
                const termDescription = termData["Term Description"];
                const termHarms       = termData["Harms"];
                // store elements that will be populated with content
                const positivePropertyItem        = document.getElementById("positive_property_item");
                const positivePropertyLabel       = document.getElementById("positive_property_label_text");
                const positivePropertyHeading     = document.getElementById("positive_property_heading");
                const positivePropertyDescription = document.getElementById("positive_property_description");
                const positivePropertyHarms       = document.getElementById("positive_property_harms");
                const positivePropertyHarmsList   = document.getElementById("positive_property_harms_list");
                // set aria-label on positive property list item
                positivePropertyItem.ariaLabel = `Positive Property: ${termName}`;
                // set text displaying in label on positive property list item
                positivePropertyLabel.innerText = `Positive Property: ${termName}`;
                // populate positive property heading with content
                positivePropertyHeading.innerText = termName;
                // populate positive property description with content
                if (termDescription) {
                    positivePropertyDescription.innerHTML = `<p>${termDescription}</p>`;
                    positivePropertyDescription.hidden = false;
                } else {
                    positivePropertyDescription.hidden = true;
                }
                // populate all positive property harms with content
                if (termHarms) {
                    const harmsContent = termHarms.map((harm) => {
                        return `
                            <li class="positive_property__related_harms__item">
                                <button
                                id="related_harm_button_${harm}"
                                class="positive_property__related_harms__button"
                                aria-label="${harm}">${harm}</button>
                            </li><!-- .positive_property__related_harms__item -->
                        `;
                    });
                    positivePropertyHarmsList.innerHTML = harmsContent.join("\n");
                    positivePropertyHarms.hidden = false;
                    termHarms.forEach(harm => {
                        const harmButton = document.getElementById(`related_harm_button_${harm}`);
                        if (harmButton) {
                            harmButton.addEventListener(
                                "click",
                                clickRelatedButtonWrapper(harm)
                            );
                        }
                    });
                } else {
                    positivePropertyHarms.hidden = true;
                }
                /*
                    assign SVG patterns as CSS background images
                    to positive property result graphics
                */
                AssignPositivePropertyGraphic(data);
                // colour this positive property's graphic
                ColourPositivePropertiesResult(data);
                // display positive property section in results to user now that content is populated
                positiveProperty.hidden = false;
            } else {
                /*
                    if `termData` is not defined at this point, last focused node has been deselected
                */
                // hide results containers
                positiveProperty.hidden = true;
                harm.hidden = true;
            }
        }
        /*
            copy resource URL when user clicks URL copy button
        */
        UrlCopyButton();
        /*
            handle visibility of resource type descriptions
            when user interacts with relevant button
        */
        ResourceTypeInformation();
        /*
            allow enchanced dialog accessibility (depends on a11y-dialog.js)
        */
        // DialogAccessibility();
    }
    /*
        add a comment here describing the function
    */
    function addTableRow (resourceName, indexedData, indexedResourceTypes, index, resource_type="") {
        const resource = indexedData[resourceName];
        if (!resource) {
            return;
        }
        const resourceType              = resource["Resource Type"] || resource_type;
        const urlParams                 = new URLSearchParams(window.location.search);
        const resourceTypeQueryParam    = urlParams.get("resource_type");
        const resourceFilterQueryParams = urlParams.getAll("resource_filter");

        if (resourceTypeQueryParam && resourceType !== resourceTypeQueryParam) {
            return;
        }
        /*
            if a resource filter is applied, check to see if tags
            is associated with resource; if it is, then show in results
        */
        let matchedResourceFilter = true;
        if (resourceFilterQueryParams.length) {
            matchedResourceFilter = false;
            resourceFilterQueryParams.forEach(
                resourceFilter => {
                    const splitResourceFilter = resourceFilter.split(":");
                    if (splitResourceFilter.length === 2) {
                        if (resource["Tags"][splitResourceFilter[0]].includes(splitResourceFilter[1])) {
                            matchedResourceFilter = true;
                        }
                    }
                }
            );
        }
        if (!matchedResourceFilter) {
            return;
        }
        const resourceAuthors  = resource["Authors"] && resource["Authors"].length ? resource["Authors"][0] + " ...": "";
        const resourceFullUrl  = resource["Url"];
        const resourceShortUrl = resourceFullUrl ? new URL(resourceFullUrl).hostname.replace("www.", ""): "";
        let content = `
            <tr>
                <td
                data-column-heading="State of the Art">
                    <div class="harm__related_resources__table__resource_type">
                        <button class="harm__related_resources__table__resource_type__button">
                            <span class="harm__related_resources__table__resource_type__text">${resourceType}</span>
                            <svg
                            fill="none"
                            height="15"
                            viewBox="0 0 15 15"
                            width="15"
                            xmlns="http://www.w3.org/2000/svg">
                                <path
                                clip-rule="evenodd"
                                d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM8.24992 4.49999C8.24992 4.9142 7.91413 5.24999 7.49992 5.24999C7.08571 5.24999 6.74992 4.9142 6.74992 4.49999C6.74992 4.08577 7.08571 3.74999 7.49992 3.74999C7.91413 3.74999 8.24992 4.08577 8.24992 4.49999ZM6.00003 5.99999H6.50003H7.50003C7.77618 5.99999 8.00003 6.22384 8.00003 6.49999V9.99999H8.50003H9.00003V11H8.50003H7.50003H6.50003H6.00003V9.99999H6.50003H7.00003V6.99999H6.50003H6.00003V5.99999Z"
                                fill="currentColor"
                                fill-rule="evenodd"/>
                            </svg>
                        </button><!-- .harm__related_resources__table__resource_type__button -->
                        <span class="harm__related_resources__table__resource_type__information">${indexedResourceTypes[resourceType]["Term Description"]}</span>
                    </div><!-- .harm__related_resources__table__resource_type -->
                </td>
                <td data-column-heading="Resource title">${resourceName}</td>
                <td data-column-heading="Resource author/s">${resourceAuthors}</td>
                <td
                class="harm__related_resources__table__resource_location"
                data-column-heading="Resource link">
        `;
        if (resourceShortUrl) {
            content += `
                <a
                class="harm__related_resources__table__resource_anchor"
                href="${resourceFullUrl}"
                target="_blank"
                title="Visit external link">
                    <span class="harm__related_resources__table__resource_anchor__text">${resourceShortUrl}…</span>
                    <svg
                    class=""
                    fill="none"
                    height="15"
                    viewBox="0 0 15 15"
                    width="15"
                    xmlns="http://www.w3.org/2000/svg">
                        <path
                        clip-rule="evenodd"
                        d="M3 2C2.44772 2 2 2.44772 2 3V12C2 12.5523 2.44772 13 3 13H12C12.5523 13 13 12.5523 13 12V8.5C13 8.22386 12.7761 8 12.5 8C12.2239 8 12 8.22386 12 8.5V12H3V3L6.5 3C6.77614 3 7 2.77614 7 2.5C7 2.22386 6.77614 2 6.5 2H3ZM12.8536 2.14645C12.9015 2.19439 12.9377 2.24964 12.9621 2.30861C12.9861 2.36669 12.9996 2.4303 13 2.497L13 2.5V2.50049V5.5C13 5.77614 12.7761 6 12.5 6C12.2239 6 12 5.77614 12 5.5V3.70711L6.85355 8.85355C6.65829 9.04882 6.34171 9.04882 6.14645 8.85355C5.95118 8.65829 5.95118 8.34171 6.14645 8.14645L11.2929 3H9.5C9.22386 3 9 2.77614 9 2.5C9 2.22386 9.22386 2 9.5 2H12.4999H12.5C12.5678 2 12.6324 2.01349 12.6914 2.03794C12.7504 2.06234 12.8056 2.09851 12.8536 2.14645Z"
                        fill="currentColor"
                        fill-rule="evenodd"/>
                    </svg>
                </a><!-- harm__related_resources__table__resource_anchor -->
                <button
                class="harm__related_resources__table__copy_anchor_button"
                aria-label="Copy external link"
                title="Copy external link">
                    <svg
                    class=""
                    fill="none"
                    height="15"
                    viewBox="0 0 15 15"
                    width="15"
                    xmlns="http://www.w3.org/2000/svg">
                        <path
                        clip-rule="evenodd"
                        d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
                        fill="currentcolor"
                        fill-rule="evenodd"/>
                    </svg>
                </button><!-- .harm__related_resources__table__copy_anchor_button -->
            `;
        }
        content += `
                </td><!-- .harm__related_resources__table__resource_location -->
                <td
                class="harm__related_resources__table__resource_details"
                data-column-heading="Full details">
                    <button
                    class="harm__related_resources__table__resource_details__button"
                    id="resource_details_button_${index}"
                    title="Full details">
                        <svg
                        class=""
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                            <path
                            clip-rule="evenodd"
                            d="M12 13C12.5523 13 13 12.5523 13 12V3C13 2.44771 12.5523 2 12 2H3C2.44771 2 2 2.44771 2 3V6.5C2 6.77614 2.22386 7 2.5 7C2.77614 7 3 6.77614 3 6.5V3H12V12H8.5C8.22386 12 8 12.2239 8 12.5C8 12.7761 8.22386 13 8.5 13H12ZM9 6.5C9 6.5001 9 6.50021 9 6.50031V6.50035V9.5C9 9.77614 8.77614 10 8.5 10C8.22386 10 8 9.77614 8 9.5V7.70711L2.85355 12.8536C2.65829 13.0488 2.34171 13.0488 2.14645 12.8536C1.95118 12.6583 1.95118 12.3417 2.14645 12.1464L7.29289 7H5.5C5.22386 7 5 6.77614 5 6.5C5 6.22386 5.22386 6 5.5 6H8.5C8.56779 6 8.63244 6.01349 8.69139 6.03794C8.74949 6.06198 8.80398 6.09744 8.85143 6.14433C8.94251 6.23434 8.9992 6.35909 8.99999 6.49708L8.99999 6.49738"
                            fill="currentColor"
                            fill-rule="evenodd"/>
                        </svg>
                    </button><!-- .harm__related_resources__table__resource_details__button -->
                </td><!-- .harm__related_resources__table__resource_details -->
            </tr>
        `;
        return content;
    }
    /*
        add a comment here describing the function
    */
    function addResourceDetailsButtonEventListener (resourceName, indexedData, index, tags) {
        const resourceTitleButton = document.getElementById(`resource_details_button_${index}`);
        const resource            = indexedData[resourceName];
        if (resourceTitleButton){
            resourceTitleButton.addEventListener(
                "click",
                populateResourceDialogWrapper(resource, tags)
            );
        }
    }
    /*
        initialise D3.js
    */
    var sankeyConfig = {
        height: 390,
        linkColor: "source",  // e.g., "source" or "target"; set by input above
        nodeAlign: "left",    // e.g., d3.sankeyJustify; set by input above
        nodeGroup: d => d.id, // use positive property names to apply colours
        width: 800,
    };
    var data;
    function renderGraph () {
        sankeyChart({data}, sankeyConfig);
    }
    /*
        add event handlers for search clear and submit buttons
    */
    $(document).on(
        "click", "#search_submit", select2SubmitWrapper("search_input", "filter")
    );
    $(document).on(
        "click", "#clear_terms_button", select2ClearWrapper("filter")
    );
    $(document).on(
        "click", "#resource_search_submit", select2SubmitWrapper("resource_search_input", "resource_filter")
    );
    $(document).on(
        "click", "#clear_resource_terms_button", select2ClearWrapper("resource_filter")
    );
    /*
        retrieve JSON data,
        render the graph using the data and sankey chart configuration options,
        update page content when url changes
    */
    // retrieve JSON data
    d3.json("/assets/files/json_data/map_data.json").then(map_data => {
        data = map_data;
        // render the graph using the data and sankey chart configuration options
        function renderGraph () {
            sankeyChart({data}, sankeyConfig);
        }
        // update page content when url changes
        window.addEventListener("popstate", () => {
            updatePageContent(data);
            renderGraph();
        });
        renderGraph();
    });
}
