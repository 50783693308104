import ColoursList from "./positive_property_colours_and_patterns";
import RetrieveColours from "./retrieve_positive_property_colours";
/*
    assign SVG patterns as CSS background images to positive property result graphics
*/
export default function (data) {
    // retrieve positive properties graphic element
    const graphicElement = document.getElementById("positive_property_graphic");
    // retrieve positive property name
    const name = document.getElementById("positive_property_heading").innerText;
    if (graphicElement && name) {
        // retrieve & apply relevant border colour to the graphic element
        const borderColour = RetrieveColours(data, name, "border");
        graphicElement.style.borderColor = borderColour;
        // retrieve all positive properties from the data
        const positiveProperties = data["Positive Properties"];
        // loop over positive properties
        positiveProperties.forEach((positiveProperty) => {
            const term = positiveProperty["Term"];
            // find the row which matches `positivePropertyName`
            if (term === name) {
                // store the relevant colour pair number
                const colourPairNumber = positiveProperty["Colour Pattern Pair"];
                // retrieve & apply the relevant background image to the graphic element,
                // and set the background image as a CSS custom property
                const pattern = ColoursList[colourPairNumber].pattern;
                if (pattern) {
                    const label = document.getElementById("positive_property_label");
                    if (label) {
                        label.style.backgroundImage = `url(assets/images/patterns/${pattern}.svg)`;
                    }
                    graphicElement.style.backgroundImage = `url(assets/images/patterns/${pattern}.svg)`;
                } else {
                    // retrieve & apply relevant background colour to the graphic element
                    const backgroundColour = RetrieveColours(data, name, "background");
                    graphicElement.style.backgroundColor = backgroundColour;
                }
            }
        });
    }
}
