/*
    a list of colours and patterns
*/
const colourPatternPairs = {
    "1": {
        "background": "hsla(360, 82%, 68%, 1)",
        "border": "hsla(360, 87%, 58%, 1)",
        "pattern": "pattern_01",
    },
    "2": {
        "background": "hsla(36, 96%, 65%, 1)",
        "border": "hsla(36, 100%, 55%, 1)",
        "pattern": "pattern_02",
    },
    "3": {
        "background": "hsla(72, 88%, 63%, 1)",
        "border": "hsla(72, 93%, 43%, 1)",
        "pattern": "pattern_03",
    },
    "4": {
        "background": "hsla(108, 85%, 68%, 1)",
        "border": "hsla(108, 90%, 58%, 1)",
        "pattern": "pattern_04",
    },
    "5": {
        "background": "hsla(144, 77%, 58%, 1)",
        "border": "hsla(144, 82%, 48%, 1)",
        "pattern": "pattern_05",
    },
    "6": {
        "background": "hsla(180, 79%, 69%, 1)",
        "border": "hsla(180, 84%, 59%, 1)",
        "pattern": "pattern_06",
    },
    "7": {
        "background": "hsla(216, 92%, 68%, 1)",
        "border": "hsla(216, 100%, 58%, 1)",
        "pattern": "pattern_07",
    },
    "8": {
        "background": "hsla(252, 87%, 71%, 1)",
        "border": "hsla(252, 92%, 61%, 1)",
        "pattern": "pattern_08",
    },
    "9": {
        "background": "hsla(288, 71%, 65%, 1)",
        "border": "hsla(288, 76%, 55%, 1)",
        "pattern": "pattern_09",
    },
    "10": {
        "background": "hsla(326, 100%, 75%, 1)",
        "border": "hsla(326, 100%, 65%, 1)",
        "pattern": "pattern_10",
    },
};
export default colourPatternPairs;
