import RetrieveColours from "./retrieve_positive_property_colours";
/*
    colour positive properties result
*/
export default function (data) {
    // retrieve positive property name
    const name = document.getElementById("positive_property_heading").innerText;
    if (name) {
        // retrieve and store the DOM's root element
        const rootElement = document.documentElement;
        // retrieve positive property colour
        const colour = RetrieveColours(data, name, "background");
        // set CSS custom property on the DOM's root element
        rootElement.style.setProperty("--colour-positive-property", colour);
    }
}
