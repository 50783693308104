/*
    handle visibility of resource type descriptions
    when user interacts with relevant button
*/
export default function () {
    // retrieve all resource type table entries
    const resourceTypes = [...document.getElementsByClassName("harm__related_resources__table__resource_type")];
    if (resourceTypes) {
        resourceTypes.forEach(resourceType => {
            const button      = resourceType.querySelector(".harm__related_resources__table__resource_type__button");
            const information = resourceType.querySelector(".harm__related_resources__table__resource_type__information");
            if (button && information) {
                button.addEventListener("click", () => {
                    const classname = "harm__related_resources__table__resource_type__information--visible";
                    information.classList.add(classname);
                    setTimeout(() => {
                        information.classList.remove(classname);
                    }, 2500);
                });
            }
        });
    }

}
